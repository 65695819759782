import React, { useState, useContext, useEffect } from "react";
import Loader from "components/loader";
import DataProvider from "Context/DataContext";
import { Link, navigate } from "gatsby";
import { usePostSignIn } from "hooks/Auth/useSignInForm";
import { useForm } from "react-hook-form";
import formCommonPostData from "utils/form-common-post-data";

export default function Login() {
  const { status: Status, mutateAsync: signInMutateAsync } = usePostSignIn();
  const [toggle, setToggle] = useState(false);
  const { setUserMail, setAccountInfo, LocalObject } = useContext(DataProvider);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    register: Register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const checkLogin = LocalObject.localStorage.getItem("access_token");
    if (checkLogin) {
      return navigate("/user/dashboard");
    }
  }, []);

  const SignInSubmit = (data) => {
    const postData1 = {
      username: data.email,
      password: data.password,
    };

    setErrorMessage("");
    const postData = { ...postData1, ...formCommonPostData() }
    signInMutateAsync(postData).then((res) => {
      if (res.success === 1) {
        reset();
        LocalObject.localStorage.setItem("access_token", res.access_token);
        LocalObject.localStorage.setItem("email", res.user.email);
        LocalObject.localStorage.setItem("acc_info", JSON.stringify(res));
        return navigate("/user/dashboard");
      } else {
        setErrorMessage(res.message);
      }
    });
  };

  const styles = {
    background: "transparent linear-gradient(180deg, #F1F6F8 0%, #F9FAFB 61%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
  };

  return (
    <React.Fragment>
      <div className="justify-around sm:justify-center items-center flex  outline-none focus:outline-none font-primary bg-midBlue">
        <form style={styles} className="relative  my-6 sm:mx-auto max-w-[482px] w-11/12 sm:w-full px-2 sm:px-10  py-8" onSubmit={handleSubmit(SignInSubmit)}>
          {/* <span onClick={() => setOpen(false)} className="w-10 h-10 bg-black text-white rounded-full absolute right-7 leading-10  text-center cursor-pointer">
                  X
                </span> */}
          <p className="text-center text-[35px] font-secondary font-semibold my-5">Sign in</p>
          <h2 className="text-center text-base font-normal font-arial mb-8 ">Sign in to your Tomedes account.</h2>
          <label className="mb-5 block">
            <input
              type="text"
              className="text-lg font-arial font-normal px-4 w-full h-[50px] border border-quotetext-100 rounded-lg mb-1"
              placeholder="Email address"
              name="email"
              {...Register("email", { required: true })}
            />
            {errors.email && <span className="text-xs font-opensans font-normal text-left w-full text-red">This field is required.</span>}
          </label>
          <label className="mb-5 block">
            <input
              type="password"
              className="text-lg font-arial font-normal px-4 w-full h-[50px] border border-quotetext-100 rounded-lg mb-1"
              placeholder="Password"
              name="password"
              {...Register("password", { required: true })}
            />
            {errors.password && <span className="text-xs font-opensans font-normal text-left w-full text-red">This field is required.</span>}
          </label>
          <p className="text-base font-opensans text-center text-red" style={{ marginTop: "2rem" }}>
            {errorMessage ? errorMessage : ""}
          </p>
          <p className="text-base font-opensans text-right mb-4">
            <a href="/user/forgot-password" className="text-base font-opensans text-[#273F77] capitalize" target="_blank" >forgot password?</a>
          </p>
          <input type="hidden" id="zc_gad" name="zc_gad" value="" />
          <button className="w-full h-[56px] bg-orange text-white font-arial text-xl my-8 rounded-lg font-bold" type="submit" style={{ marginTop: "0px" }}>
            {Status === "loading" ? <Loader /> : "Sign in"}
          </button>
          <p className="text-base text-[15px] font-opensans text-darkBlue mb-4">We have changed the quote process to serve you better.
            <span className="font-bold"> For all existing clients, </span>
            you may now &nbsp;
            <span className="hidden sm:block"></span>
            <a href="/user/forgot-password" className="text-orange underline" target="_blank">
              reset your password
            </a>
            &nbsp; in order to access your account.</p>
          <p className="text-base text-[15px] font-opensans text-darkBlue">
            <span className="font-bold">For new clients, </span>
            create your &nbsp;
            <Link to="/user/register" className="cursor-pointer text-orange underline">
              free account
            </Link>
            &nbsp; now.
          </p>

          {/* <p className="text-base font-opensans text-center">
                  Create your free account.
                    <Link className="cursor-pointer text-orange underline" to="/user/register"> Sign up</Link>
                  {"  "}
                  now.
                </p> */}
        </form>
      </div>
    </React.Fragment>
  )
}
